
* { margin: 0; padding: 0; }

body {
  font-family: 'Poppins', sans-serif; 
}


/* navvvvvv */

.logogo{
  padding: 10px 30px 0 0%;
  height: 75px;
  /* border: 1px solid red; */
}

.navbar{
  box-shadow: -5px 4px 16px rgba(0, 0, 0, 0.1);
  /* border: solid 1px red; */
  padding: 10px 8%;
}

.navvv{
  /* border: 1px solid red; */
  display: flex;
  justify-content: end;
  width: 100%;
}

.navni{
  /* border: solid 1px red; */
  gap: 18px;
  padding-top: 8px;
}

.custom-dropdown-title {
  color: #3f3f3f;
  font-weight: 400;
}

.lognav{
  /* border: solid 1px red; */
  padding-right: 15px;
}

.navtext {
  color: #3f3f3f;
  font-weight: 500;
  font-size: 20px;
  /* border: 1px solid yellow; */
}

.btnstarted{
  background-color: #4268AE;
  color: white;
  border-radius: 2px;
  border-style: none;
}



 /* ---------  First Section ----------- */

 .hero{
  display: flex;
  height: 70vh;
  /* border: solid 2px green; */
  justify-content: center;
 }

 .heroleft{
  width: 50%;
  /* border: solid 1px red; */
  padding: 1% 5% 5% 9%;
 }

  .heroleft h4 {
    font-size: 26px;
  }

 .heroleft h3{
  font-weight: 400;
  padding: 0 0 3% 0;
  font-size: 28px;
 }

 .heroright{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding-right: 2%;
  background-color: #F5F5F5;
 }

 .heroimg{
  height: 75%;
  border-radius: 20px;
 }

 .banner1{
  background-color: #D3E1EC;
  padding: 3% 2%
 }

 .parahero{
  font-size: 17px;
}

 .forsix{
  color: #4268AE;
 }

 .custom-divider {
  border-bottom: 6px solid #FFA336;
  margin: 25px 88% 20px 0px;
}

.btnhero1{
  padding: 8px 15px;
  margin-right: 15px;
  background-color: #4268AE;
  border: none;
  color: white;
  border-radius: 2px;
  font-size: 17px;
}

/* ------------ Promo ---------- */

/* Base styles (for larger screens) */
.promo {
  background-image: url('../public/images/promobgg.jpg');
  height: 25vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 5%;
  gap: 30px;
}

.pro1, .pro2, .pro3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pro1 {
  width: 30%;
  height: 20vh;
}

.pro2 {
  width: 20%;
  height: 20vh;
  align-items: center;
}

.pro3 {
  width: 18%;
  height: 20vh;
  align-items: center;
}

.pro3 img {
  height: 100%;
}

.pro1 h3 {
  color: #db221d;
  font-weight: 700;
}

.pro1 h5 {
  font-weight: 600;
  line-height: 130%;
}

.pro2 p {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}

.pro2 button {
  font-size: 18px;
  padding: 8px 15px;
  border-style: none;
  background-color: white;
  color: #db221d;
  font-weight: 600;
}

.pro4 {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 23%;
}

.pro4 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* Medium screens (tablets, small laptops) */
@media (max-width: 1024px) {
  .promo {
    gap: 30px;
    margin-bottom: 5%;
  }

  .pro1, .pro2, .pro3 {
    width: 25;
  }

  .pro1, .pro2 {
    height: auto;
  }

  .pro4 {
    width: 20%;
  }
}

/* Small screens (phones, small tablets) */
@media (max-width: 768px) {
  .promo {
    flex-direction: column;
    padding-left: 0;
    padding-top: 40px;
    gap: 20px;
    height: auto;
  }

  .pro2 button {
    font-size: 22px;
    padding: 7px 14px;
  }

  .pro1, .pro2, .pro3 {
    width: 95%;
    text-align: center;
  }

  .pro1, .pro2 {
    height: auto;
  }

  .pro4 {
    position: relative;
    width: 100%;
    height: 25vh;
  }
}

/* Extra small screens (very small phones) */
@media (max-width: 576px) {
  .pro1 h3 {
    font-size: 30px;
  }

  .pro1 h5, .pro2 p {
    font-size: 20px;
  }

  .pro2 button {
    font-size: 22px;
    padding: 7px 14px;
  }
}

 /* ------ toolbox ---------- */

 
.toolboxtitle h4{
  font-size: 26px;
}
 
.toolboxtitle p{
  font-size: 19px;
}

 .card {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 16px;
  width: 280px;
  text-align: start;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
}

.card-image-container {
  padding: 10px 10px 1px 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  /* border: solid 1px red; */
  display: flex;
  justify-content: center;
}

.card-image {
  width: 225px;
  height: 150px;
  margin-bottom: 10px;

}

.card-title {
  font-size: 18px;
  margin: 16px 0 8px;
  color: #333;
}

.card-description {
  font-size: 14px;
  color: #666;
  margin: 0;
}

.card-button {
  padding: 8px 18px;
  font-size: 14px;
  color: #fff;
  background-color: #3f7cbf;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  margin-top: 16px;
}

.toolbox{
  /* border: solid 1px rgb(51, 51, 51); */
  padding: 0 4%;
 }

 .toolboxtitle{
  /* border: solid 1px rgb(51, 51, 51); */
  padding: 2% 0 1% 5%;
 }

.toolcard1{
  display: flex;
  justify-content: space-between;
  padding: 0 5% 2% 5%;
  gap: 4%;
  /* border: solid 1px green; */
  padding-bottom: 2%;
 
}

.strongs{
  font-weight: 600;
}

.toolcard2{
  /* border: solid 1px green; */
  display: flex;
  justify-content: center;
  gap: 4%;
  justify-content: space-between;
  padding: 0 5% 2% 5%;
 }

.toolcard3{
  /* border: solid 1px green; */
  display: flex;
  justify-content: space-between;
  padding: 0 5% 2% 5%;
  gap: 4%;
}



 /* banner 2 */

 .contain {
  padding: 1% 9%;
  /* border: solid 1px green; */
}

h1 {
  font-size: 24px;
  color: #000000;
  margin-bottom: 20px;
}

.step h2 {
  font-size: 19px;
  color: #4268AE;
  margin-top: 20px;
}

.step h2 span {
  font-weight: normal;
  color: #000000;
}

.step p, .info p {
  font-size: 15px;
  color: #333333;
  line-height: 1.6;
}

.info h2 {
  font-size: 18px;
  color: #4268AE;
  margin-top: 20px;
}

.ctaspan{
  color: #4268AE;
}

.pcta{
  font-size: 20px;
  font-weight: 600;
}

.acta{
 text-decoration: none;
}
/* 
.cta-button {
  display: block;
  margin: 30px 0;
  padding: 15px;
  background-color: #FFD9AB;
  color: #000000;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 100%;
  text-decoration: none;
}

.cta-button:hover {
  background-color: #FFA336;
} */

@media screen and (max-width: 768px) {
  .contain h1 {
    font-size: 25px;
  }
  .contain h2 {
    font-size: 23px;
  }
  .contain p {
    font-size: 19px;
  }
  .contain button {
    font-size: 19px;
  }

}

@media screen and (max-width: 1545px) {
  .heroimg {
    height: auto;
    width: 90%;
  }

  .toolcard1, .toolcard2, .toolcard3 {
    margin: 0 20px;
  }
}
@media screen and (max-width: 1200px) {
  .hero {
    height: auto;
    flex-direction: column;
  }

  .heroleft, .heroright {
    width: 100%;
    padding: 5%;
  }

  .heroimg {
    height: auto;
    width: 100%;
  }

  .toolcard1, .toolcard2, .toolcard3 {
    margin: 0 30px;
  }

  .custom-divider {
    margin: 25px 88% 40px 0;
  }
}

@media screen and (max-width: 992px) {
  .heroleft h3 {
    font-size: 24px;
  }

  .btnhero1, .btnhero2 {
    padding: 8px 15px;
  }

  .toolboxtitle {
    padding: 2% 5% 1% 5%;
  }

  .card-title {
    font-size: 23px;
  }

  .card-description {
    font-size: 17px;
  }

  .card {
    width: 100%;
    padding: 12px;
  }

  .card-image {
    width: 100%;
    height: auto;
  }

  .toolcard1, .toolcard2, .toolcard3 {
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
  }

  .toolcard1 .card, .toolcard2 .card, .toolcard3 .card {
    width: 90%;
    margin-bottom: 20px;
  }

  .btnhero1{
    padding: 8px 15px;
    margin-right: 15px;
    background-color: #4268AE;
    border: none;
    color: white;
    border-radius: 2px;
    font-size: 17px;
  }

}

@media screen and (max-width: 768px) {
  .heroleft h4 {
    font-size: 24px;
  }
  .heroleft h3 {
    font-size: 23px;
  }

  .heroleft p {
    font-size: 19px;
  }

  .heroleft{
    padding: 8px 5% 5% 9%;
   }

  .hero{
    flex-direction: column-reverse;
  }

  .btnhero1, .btnhero2 {
    padding: 8px 15px;
    font-size: 20px;
  }

  .toolboxtitle {
    padding: 2% 3% 1% 3%;
  }

  .card {
    width: 100%;
    padding: 10px;
  }

  .card-image {
    width: 100%;
    height: auto;
  }
  
  .card-title {
    font-size: 23px;
  }

  .card-description {
    font-size: 19px;
  }

  .card-button {
    font-size: 19px;
  }

  .pcta{
    font-size: 21px;
  }

 .banner{
  padding: 1%;
 }

 .toolcard1, .toolcard2, .toolcard3 {
  padding: 3% 1%;
  width: 100%;
  margin: 2% 0;
}
 
}

@media screen and (max-width: 576px) {

  .toolboxtitle {
    padding: 1% 2% 1% 2%;
  }

  .card-title {
    font-size: 23px;
  }

  .card-description {
    font-size: 19px;
  }

  .card-button {
    font-size: 19px;
  }

  .card {
    width: 100%;
    padding: 8px;
  }

  .card-image {
    width: 100%;
    height: auto;
  }

}


/* ------------------------ Popup styles ----------------- */

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 70%;
  max-height: 82%;
  overflow-y: auto;
  position: relative;
  box-sizing: border-box;
  
}

/* Popup Image */
.popup-image {
  width: 100%;
  height: 60vh;
  border-radius: 5px;
  border: solid 1px #4268AE;
}

/* Popup Title */
.popup-title {
  font-size: 24px;
  margin: 10px 0;
}

/* Popup Description */
.popup-description {
  display: none;
}

/* Popup Buttons */
.popup-button,
.popup-more-info-button {
  background-color: #FFD9AB;
  color: rgb(50, 50, 50);
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.popup-more-info-button {
  background-color: #4268AE;
  color: #ffffff;
  margin-left: 2%;
}

.watch-video {
  background-color: #007bff;
}

.hide-video {
  background-color: #dc3545;
}

/* Video Iframe */
iframe {
  display: block;
  margin-top: 3%;
  border-radius: 4px;
  width: 100%;
  height: 60vh;
  /* border: solid 2px green; */
  border: solid 1px rgba(60, 60, 60, 0.539);
}

/* Close Button */
.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

/* Responsive Styles */

/* For screens larger than 768px */
@media (min-width: 769px) {
  .popup-content {
    width: 90%;
    height: 90%;
    border-radius: 8px;
  }
}

/* For screens 768px and below */
@media (max-width: 768px) {
  .popup {
    padding: 0;
  }

  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 82%;
    overflow-y: auto;
    position: relative;
    box-sizing: border-box; 
  }
  
  .popup-title {
    font-size: 24px !important;
  }

  .popup-description {
    display: none; 
  }

  .popup-button,
  .popup-more-info-button {
    padding: 8px 15px;
    font-size: 22px;
  }

  .popup-image {
    width: 83vw;
    height: 35vh;
    border-radius: 5px;
    border: solid 1px #4268AE;
  }

  iframe {
    display: block;
    margin-top: 3%;
    border-radius: 4px;
    width: 83vw;
    height: 30vh;
    /* border: solid 2px green; */
    border: solid 1px rgba(60, 60, 60, 0.539);
  }
  
}

