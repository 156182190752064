* { margin: 0; padding: 0; font-family: 'Josefin Sans', sans-serif;
    font-family: 'Poppins', sans-serif; }


.foot{
    background-color: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 170px;
    /* border: solid 1px red; */
}

.ftlogo{
    /* border: solid 1px violet; */
    padding: -5%;
}
.contac{
    /* border: solid 1px green; */
    color: rgb(42, 42, 42);
    padding: 0 8% 0 8%;
    line-height: 2vh;
}
.btnfoot{
    /* border: solid 1px yellow; */
    width: 13%;
}
.btnft{
    padding: 6% 5%;
    width: 100%;
    color: white;
    border-radius: 6px;
    background-color: #4268AE;;
    border-style: none;
    /* border: solid 1px green; */
}

@media screen and (max-width: 950px){.foot{flex-direction: column; height: auto;}.btnft{width: 100%;}.btnfoot{width: 50%; margin: 2%;}.ftlogo{margin: 6% 0; height: 80px;}}
@media screen and (max-width: 950px){.btnfoot{width: 70%; margin: 2%;}}